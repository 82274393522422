<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "NOVO" : "ATUALIZAR" }} CONTRATO</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Número Contrato"
              v-model="dataNumberContract"
              class="mt-5 w-full"
              name="item-number-contract"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-number-contract')"
            >{{ errors.first('item-number-contract') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Código Bacia"
              v-model="dataCodeBowl"
              class="mt-5 w-full"
              name="item-code-bowl"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-code-bowl')"
            >{{ errors.first('item-code-bowl') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vs-component vs-con-input-label vs-input mt-5 w-full vs-input-primary">
              <label for class="vs-input--label">Data Início</label>
              <datepicker
                :language="ptBR"
                v-model="dataStartDate"
                name="item-start-date"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('item-start-date')"
              >{{ errors.first('item-start-date') }}</span>
            </div>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Nome Bacia"
              v-model="dataNameSubBowl"
              class="mt-5 w-full"
              name="item-name-sub-bowl"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-name-sub-bowl')"
            >{{ errors.first('item-name-sub-bowl') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vs-component vs-con-input-label vs-input mt-5 w-full vs-input-primary">
              <label for class="vs-input--label">Prev. Término Obras</label>
              <datepicker
                :language="ptBR"
                v-model="dataEndDate"
                name="item-end-date"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('item-end-date')"
              >{{ errors.first('item-end-date') }}</span>
            </div>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Contratada"
              v-model="dataContracted"
              class="mt-5 w-full"
              name="item-contracted"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-contracted')"
            >{{ errors.first('item-contracted') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              v-currency="currencyTmr"
              label="TMR"
              v-model="dataTmr"
              class="mt-5 w-full"
              name="item-tmr"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-tmr')"
            >{{ errors.first('item-tmr') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              v-currency="currencyGlobalValue"
              icon-pack="feather"
              icon="icon-dollar-sign"
              label="Valor Global (Contratual)"
              v-model="dataGlobalValue"
              class="mt-5 w-full"
              v-validate="{ required: true}"
              name="item-global-value"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-global-value')"
            >{{ errors.first('item-global-value') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Meta Economia"
              v-model="dataGoalSavings"
              class="mt-5 w-full"
              name="item-start-savings-goals"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-start-savings-goals')"
            >{{ errors.first('item-start-savings-goals') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Limite Mínimo DBO"
              v-model="dataMinimumDbo"
              class="mt-5 w-full"
              name="item-minimum-dbo"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-minimum-dbo')"
            >{{ errors.first('item-minimum-dbo') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Fonte de Recurso"
              v-model="dataFinancing"
              class="mt-5 w-full"
              name="item-financing"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-financing')"
            >{{ errors.first('item-financing') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Meses Fase Implantação"
              v-model="dataImplantationPhaseMonths"
              class="mt-5 w-full"
              name="item-implantation-phase-months"
              v-validate="'required|integer'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-implantation-phase-months')"
            >{{ errors.first('item-implantation-phase-months') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              label="Meses Fase Apuração"
              v-model="dataInvestigationPhaseMonths"
              class="mt-5 w-full"
              name="item-investigation-phase-months"
              v-validate="'required|integer'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-investigation-phase-months')"
            >{{ errors.first('item-investigation-phase-months') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-input
              v-validate="'required|integer'"
              label="Meses Fase Renda Fixa"
              v-model="dataFixedRemunerationPhaseMonths"
              class="mt-5 w-full"
              name="item-remuneration-phase-months"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-remuneration-phase-months')"
            >{{ errors.first('item-remuneration-phase-months') }}</span>
          </div>

          <div class="vx-col w-full">
            <vs-textarea
              label="Objeto"
              v-model="dataObject"
              class="mt-5 w-full"
              name="item-object"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('item-object')"
            >{{ errors.first('item-object') }}</span>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Salvar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import Datepicker from "vuejs-datepicker";
  import {ptBR} from "vuejs-datepicker/dist/locale";
  import {CurrencyDirective,parseCurrency} from 'vue-currency-input'

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return;
        if (Object.entries(this.data).length === 0) {
          this.initValues();
          this.$validator.reset();
        } else {
          let {
            id,
            numberContract,
            codeBowl,
            startDate,
            nameSubBowl,
            endDate,
            contracted,
            tmr,
            globalValue,
            goalSavings,
            minimumDbo,
            object,
            financing,
            implantationPhaseMonths,
            investigationPhaseMonths,
            fixedRemunerationPhaseMonths
          } = JSON.parse(JSON.stringify(this.data));

          this.dataId = id;
          this.dataNumberContract = numberContract;
          this.dataCodeBowl = codeBowl;
          this.dataStartDate = startDate;
          this.dataNameSubBowl = nameSubBowl;
          this.dataEndDate = endDate;
          this.dataContracted = contracted;
          this.dataTmr = this.$options.filters.formatToDecimal(tmr, 4);
          this.dataGlobalValue = this.$options.filters.formatToDecimal(globalValue, 2);
          this.dataGoalSavings = goalSavings;
          this.dataMinimumDbo = minimumDbo;
          this.dataFinancing = financing;
          this.dataObject = object;
          this.dataImplantationPhaseMonths = implantationPhaseMonths;
          this.dataInvestigationPhaseMonths = investigationPhaseMonths;
          this.dataFixedRemunerationPhaseMonths = fixedRemunerationPhaseMonths;

          this.initValues();
        }
      }
    },
    data() {
      return {
        locale: "pt-BR",
        currency: null,

        date: null,
        ptBR: ptBR,

        dataId: null,
        dataNumberContract: "",
        dataCodeBowl: "",
        dataStartDate: "",
        dataNameSubBowl: "",
        dataEndDate: "",
        dataObject: "",
        dataContracted: "",
        dataGlobalValue: 0,
        dataTmr: 0,
        dataGoalSavings: "",
        dataMinimumDbo: "",
        dataFinancing: "",
        dataImplantationPhaseMonths: "",
        dataInvestigationPhaseMonths: "",
        dataFixedRemunerationPhaseMonths: "",

        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.6
        }
      };
    },
    computed: {
      currencyTmr() {
        return {
          locale: this.locale,
          currency: this.currency,
          precision: 4,
        }
      },
      currencyGlobalValue() {
        return {
          locale: this.locale,
          currency: this.currency,
          precision: 2
        }
      },
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive;
        },
        set(val) {
          if (!val) {
            this.$emit("closeSidebar");
          }
        }
      },
      isFormValid() {
        return !this.errors.any();
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return;
        this.dataId = null;
        this.dataNumberContract = "";
        this.dataCodeBowl = "";
        this.dataStartDate = "";
        this.dataNameSubBowl = "";
        this.dataEndDate = "";
        this.dataContracted = "";
        this.dataTmr = 0;
        this.dataGlobalValue = 0;
        this.dataGoalSavings = "";
        this.dataMinimumDbo = "";
        this.dataObject = "";
        this.dataFinancing = "";
        this.dataImplantationPhaseMonths = "";
        this.dataInvestigationPhaseMonths = "";
        this.dataFixedRemunerationPhaseMonths = "";
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              id: this.dataId,
              numberContract: this.dataNumberContract,
              object: this.dataObject,
              codeBowl: this.dataCodeBowl,
              nameSubBowl: this.dataNameSubBowl,
              startDate: this.dataStartDate,
              endDate: this.dataEndDate,
              contracted: this.dataContracted,
              globalValue: parseCurrency(this.dataGlobalValue, this.currencyGlobalValue),
              tmr: parseCurrency(this.dataTmr,this.currencyTmr),
              goalSavings: this.dataGoalSavings,
              minimumDbo: this.dataMinimumDbo,
              financing: this.dataFinancing,
              implantationPhaseMonths: this.dataImplantationPhaseMonths,
              investigationPhaseMonths: this.dataInvestigationPhaseMonths,
              fixedRemunerationPhaseMonths: this.dataFixedRemunerationPhaseMonths
            };

            if (this.dataId !== null && this.dataId >= 0) {
              this.$store.dispatch("dataList/updateItem", obj);
            } else {
              delete obj.id;
              this.$store.dispatch("dataList/addItem", obj);
            }

            this.$emit("closeSidebar");
            this.initValues();
          }
        });
      }
    },
    directives: {
      currency: CurrencyDirective,
    },
    components: {
      VuePerfectScrollbar,
      Datepicker
    }
  };
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
