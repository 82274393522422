<template>
  <div>
    <vs-prompt
      @accept="submitData"
      :title="title"
      :active.sync="isDialogActiveLocal"
      accept-text="Liberar Pagamento"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <p>Tem certeza que deseja liberar o pagamento?</p>
        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      isActivePromptFixedRemuneration: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    watch: {
      isActivePromptFixedRemuneration(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          let {

            contractId,

          } = JSON.parse(JSON.stringify(this.data))

          this.dataContractId = contractId

          this.initValues()
        }
      }
    },
    data() {
      return {
        dataContractId: null,
      }
    },
    computed: {
      isDialogActiveLocal: {
        get() {
          return this.isActivePromptFixedRemuneration
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              contractId: this.dataContractId,
            }

            delete obj.id
            this.$store.dispatch("dataList/addFixedRemuneration", obj)
              .then(fixedRemuneration => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Sucesso',
                  text: `Ao salvar os dados do contrato nº: ${fixedRemuneration.data.numberContract}`
                })
              })
              .catch((error) => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Erro',
                  text: error.data.message
                })
              })
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
