<template>
  <div>
    <vs-prompt
      @cancel="stateDbo=''"
      @accept="submitData"
      :title="title"
      :active.sync="isDialogActiveLocal"
      accept-text="Salvar"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <vs-input v-model="dataStateDbo" class="mt-3 w-full" label="DBO"
                    v-validate="'required'" name="investigation-state-dbo"/>
          <span class="text-danger text-sm" v-show="errors.has('investigation-state-dbo')">{{ errors.first('investigation-state-dbo') }}</span>
        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      isActivePromptInvestigation: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      }
    },
    watch: {
      isActivePromptInvestigation(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          let {

            contractId,
            stateDbo,

          } = JSON.parse(JSON.stringify(this.data))

          this.dataContractId = contractId
          this.dataStateDbo = stateDbo

          this.initValues()
        }
      }
    },
    data() {
      return {
        dataContractId: null,
        dataStateDbo: "",
      }
    },
    computed: {
      isDialogActiveLocal: {
        get() {
          return this.isActivePromptInvestigation
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      initValues() {
        if (this.data.id) return
        this.dataStateDbo = ""
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              contractId: this.dataContractId,
              stateDbo: this.dataStateDbo,
            }

            delete obj.id
            this.$store.dispatch("dataList/addInvestigation", obj)
              .then(investigation => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Sucesso',
                  text: `Ao salvar os dados do contrato nº: ${investigation.data.numberContract}`
                })
              })
              .catch(() => {
                this.$vs.notify({
                  color: 'danger',
                  title: 'Erro',
                  text: 'Ao adicionar economias.'
                })
              })
            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      },
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
