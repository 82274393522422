<template>
  <div id="data-list-list-view" class="data-list-container">

    <dialog-implantation :isActivePromptImplantation="addNewDialogImplantation"
                         @closeDialog="toggleDialogImplantation"
                         :title="titleDialogImplantation" :data="dialogImplantationData"/>
    <dialog-investigation :isActivePromptInvestigation="addNewDialogInvestigation"
                          @closeDialog="toggleDialogInvestigation" :title="titleDialogInvestigation"
                          :data="dialogInvestigationData"/>
    <dialog-fixed-remuneration :isActivePromptFixedRemuneration="addNewDialogFixedRemuneration"
                          @closeDialog="toggleDialogFixedRemuneration" :title="titleDialogFixedRemuneration"
                          :data="dialogFixedRemunerationData"/>

    <dialog-update-phase :isActivePromptUpdatePhase="addNewDialogUpdatePhase"
                         @closeDialog="toggleDialogUpdatePhase"
                         :data="dialogCurrentPhaseData"/>

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"/>

    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="contracts">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

          <div v-if="$acl.check('admin')"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Adicionar Novo</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ contracts.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : contracts.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>25</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="numberContract">Dados Contrato</vs-th>
        <vs-th sort-key="nameSubBowl">Dados Bacia</vs-th>
        <vs-th sort-key="startDate">Data Início</vs-th>
        <vs-th sort-key="popularity">Valor Global (Contratual)</vs-th>
        <vs-th>
          <vx-tooltip text="Fase I: Implantação; Fase II: Apuração; Fase III: Fixa" position="top">Fase Atual /
            Progresso
          </vx-tooltip>
        </vs-th>
        <vs-th>
          % Implantação
        </vs-th>
        <vs-th>
          <vx-tooltip text="Meta de economias encaminhas para tratamento" position="top">Meta Economia</vx-tooltip>
        </vs-th>
        <vs-th>
          <vx-tooltip text="Situação de economias encaminhadas para tratamento" position="top">Total Encaminhado p/
            Tratamento
          </vx-tooltip>
        </vs-th>

        <vs-th v-if="$acl.check('manager')">Ações</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate"><strong>Nº: </strong><a href="#" @click.stop="openContractDetails(tr)">{{
              tr.numberContract
              }}</a></p>
            <p><strong>Contratada: </strong>{{ tr.contracted }}</p>
          </vs-td>

          <vs-td>
            <p><strong>Código Bacia: </strong>{{ tr.codeBowl }}</p>
            <p><strong>Nome Bacia: </strong> {{ tr.nameSubBowl }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.startDate| formatDate }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.globalValue| toCurrency }}</p>
          </vs-td>

          <vs-td>
            <div align="center">
              <p>{{ tr.currentPhase|translatePhase }}</p>
            </div>
            <div>
              <vs-progress height="20" :percent="percentageConcluded(tr)" :color="getPopularityColor(Number(90))"
                           class="shadow-md"/>
            </div>
            <div align="center" v-if="tr.currentPhase === 'implantation'">
              <p>{{ tr.countImplantations||0 }}/{{ tr.implantationPhaseMonths }}</p>
            </div>
            <div align="center" v-else-if="tr.currentPhase === 'investigation'">
              <p>{{ tr.countInvestigations||0 }}/{{ tr.investigationPhaseMonths }}</p>
            </div>
            <div align="center" v-else-if="tr.currentPhase === 'fixed_remuneration'">
              <p>{{ tr.countFixedRemunerations||0 }}/{{ tr.fixedRemunerationPhaseMonths }}</p>
            </div>
          </vs-td>
          <vs-td>{{ tr.percentageImplantations|formatToPercentage}}</vs-td>

          <vs-td>
            <p>{{ tr.goalSavings }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.totalImplantations || 0 }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap" v-if="$acl.check('manager')">
            <feather-icon v-if="$acl.check('manager')"
                          icon="ClipboardIcon"
                          svgClasses="w-5 h-5 hover:text-warning stroke-current"
                          class="ml-2"
                          @click.stop="mountDialog(tr)"
                          title="Atualizar Execução Contratual"/>
            <feather-icon v-if="$acl.check('admin')"
                          icon="EditIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="editData(tr)"
                          class="ml-2"
                          title="Editar Dados Básicos"/>
            <feather-icon v-if="$acl.check('admin')"
                          icon="RefreshCwIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          @click.stop="mountDialogUpdatePhase(tr)"
                          class="ml-2"
                          title="Alterar Fase do Contrato"/>
          </vs-td>

        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
  import DataViewSidebar from '../DataViewSidebar.vue'
  import DialogImplantation from "../../dialogs/DialogsImplantation.vue";
  import DialogInvestigation from "../../dialogs/DialogsInvestigation.vue";
  import DialogFixedRemuneration from "../../dialogs/DialogsFixedRemuneration.vue";
  import DialogUpdatePhase from "../../dialogs/DialogsUpdatePhase.vue";

  export default {
    name: "vx-data-list",
    components: {
      DataViewSidebar,
      DialogImplantation,
      DialogInvestigation,
      DialogFixedRemuneration,
      DialogUpdatePhase,
    },
    data() {
      return {
        selected: [],
        itemsPerPage: 5,
        isMounted: false,

        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},

        //Dialog Implantation
        addNewDialogImplantation: false,
        titleDialogImplantation: '',
        dialogImplantationData: {},

        //Dialog Investigation
        addNewDialogInvestigation: false,
        titleDialogInvestigation: '',
        dialogInvestigationData: {},

        //Dialog Fixed Remuneration
        addNewDialogFixedRemuneration: false,
        titleDialogFixedRemuneration: '',
        dialogFixedRemunerationData: {},

        //Dialog Update Phase
        addNewDialogUpdatePhase: false,
        dialogCurrentPhaseData: {}
      }
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx
        }
        return 0
      },
      contracts() {
        return this.$store.state.dataList.contracts
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.contracts.length
      },
    },
    methods: {
      addNewData() {
        this.sidebarData = {}
        this.toggleDataSidebar(true)
      },
      editData(data) {
        this.sidebarData = data
        this.toggleDataSidebar(true)
      },
      getPopularityColor(num) {
        if (num > 90) return "success"
        if (num > 70) return "primary"
        if (num >= 50) return "warning"
        if (num < 50) return "danger"
        return "primary"
      },
      toggleDataSidebar(val = false) {
        this.addNewDataSidebar = val
      },
      toggleDialogUpdatePhase(val = false) {
        this.addNewDialogUpdatePhase = val
      },
      toggleDialogImplantation(val = false) {
        this.addNewDialogImplantation = val
      },
      toggleDialogInvestigation(val = false) {
        this.addNewDialogInvestigation = val
      },
      toggleDialogFixedRemuneration(val = false) {
        this.addNewDialogFixedRemuneration = val
      },
      mountDialogUpdatePhase(contract) {
        this.dialogCurrentPhaseData = {
          contractId: contract.id,
          currentPhase: contract.currentPhase,
          numberContract: contract.numberContract
        }
        this.toggleDialogUpdatePhase(true)
      },
      mountDialog(contract) {
        let phase = ''
        if (contract.currentPhase === 'implantation') {
          phase = 'Implantação'
          this.titleDialogImplantation = 'Fase: ' + phase + ' - Contrato: ' + contract.numberContract
          this.toggleDialogImplantation(true)
          this.dialogImplantationData = {contractId: contract.id}
        }
        if (contract.currentPhase === 'investigation') {
          phase = 'Apuração'
          this.titleDialogInvestigation = 'Fase: ' + phase + ' - Contrato: ' + contract.numberContract
          this.toggleDialogInvestigation(true)
          this.dialogInvestigationData = {contractId: contract.id}
        }
        if (contract.currentPhase === 'fixed_remuneration') {
          phase = 'Remuneração Fixa'
          this.titleDialogFixedRemuneration = 'Fase: ' + phase + ' - Contrato: ' + contract.numberContract
          this.toggleDialogFixedRemuneration(true)
          this.dialogFixedRemunerationData = {contractId: contract.id}
        }
      },
      openContractDetails(contract) {
        this.$router.push({name: 'contractDetails', params: {'contractId': contract.id}})
      },
      percentageConcluded(contract) {
        if (contract.currentPhase === 'implantation') {
          return Number((contract.countImplantations / contract.implantationPhaseMonths) * 100)
        } else if (contract.currentPhase === 'investigation') {
          return Number((contract.countInvestigations / contract.investigationPhaseMonths) * 100)
        } else if (contract.currentPhase === 'fixed_remuneration') {
          return Number((contract.countFixedRemunerations / contract.fixedRemunerationPhaseMonths) * 100)
        }
      },
    },
    created() {
      this.$store.dispatch("dataList/fetchDataListItems")
    },
    mounted() {
      this.isMounted = true;
    }
  }
</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .contract-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
